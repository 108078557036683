<template>
  <div>
    <v-card :loading="isLoading" class="mb-2">
      <v-card-title>
        <v-icon class="mr-2">mdi-connection</v-icon>
        DAF Enerji Entegrasyon
      </v-card-title>

      <v-card-text v-if="clusterId">
        <v-form @submit.prevent="handleFormSubmit" ref="form">
          <v-row dense class="mt-5">
            <v-col>
              <v-data-table
                v-bind="dataTableAttrs"
                :headers="headers"
                :items="houses"
                :loading="isLoading"
                :options.sync="options"
                :show-select="false"
                :items-per-page="5000"
                :item-class="houseRowClass"
                hide-default-footer
              >
                <!-- eslint-disable-next-line -->
                <template v-slot:item.house_id="{ item }">
                  <rs-select-house
                    label="BB Seçin"
                    :cluster-id="clusterId"
                    v-model="item.house_id"
                    :clearable="!disabled"
                    @change:option="handleHouseSelected(item, $event)"
                    :disabled="item.isLoading"
                  />
                </template>
              </v-data-table>
            </v-col>
          </v-row>

          <rs-form-buttons
            :isLoading="isLoading"
            @cancel="handleCancelClick"
            @submit="handleFormSubmit"
            hide-submit
          />
        </v-form>
      </v-card-text>

      <v-card-text v-else>
        Devir alacak girebilmek için üst menüden toplu yaşam alanı seçin.
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import { hasDataTable, hasForm, hasPermissions } from "@/view/mixins";

export default {
  name: "HouseIntegrationList",
  mixins: [hasDataTable, hasForm, hasPermissions],
  computed: {
    ...mapGetters(["blocks", "cluster", "clusterId", "houseTypes"]),
  },
  watch: {
    clusterId: {
      handler() {
        this.$nextTick(() => {
          this.loadList();
        });
      },
      immediate: true,
    },
  },
  data() {
    return {
      houses: [],
      showAmounts: true,
      dataTableAttrs: {
        footerProps: {
          disableItemsPerPage: false,
          itemsPerPageOptions: [5000],
        },
      },
      itemsPerPage: 5000,
      headers: [
        {
          text: "Kod",
          value: "provider_name",
          sortable: false,
          searchable: "text",
          width: "120px",
          align: "center",
        },
        {
          text: "BB",
          value: "house_id",
          sortable: false,
          searchable: false,
          width: "300px",
          align: "center",
        },
      ],
    };
  },
  methods: {
    loadList() {
      if (!this.clusterId) {
        return false;
      }

      const params = {
        cluster_id: this.clusterId,
      };
      const formList = [];

      this.isLoading = true;

      this.$api
        .query("house-integrations", { params })
        .then((response) => {
          for (const index in response.data.data) {
            const item = response.data.data[index];
            item.isLoading = false;

            formList.push(item);
          }

          this.houses = formList;
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    handleFormSubmit(event) {
      if (this.isLoading || !this.$refs.form.validate()) {
        this.$toast.error("Lütfen tüm gerekli alanları doldurunuz");
        return false;
      }

      const formData = { cluster_id: this.clusterId };

      formData.houses = this.houses.filter((item) => {
        return !item.disabled;
      });

      if (formData.houses.length === 0) {
        this.$toast.error("En az bir bağımsız bölüm aktif olmalı.");
        return false;
      }

      this.isLoading = true;

      if (formData.calculation_type_id === 1) {
        delete formData.block_ids;
      }

      this.$api
        .post(`income/batch-transfer-due`, formData)
        .then(() => {
          this.$toast.success("Kaydedildi");

          if (event && event.closeOnSave) {
            this.handleCancelClick();
          }
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    handleHouseSelected(item, event) {
      item.isLoading = true;

      const formData = {
        house_id: event.item === null ? null : event.item.id,
      };

      this.$api
        .put(`house-integrations/${item.id}`, formData)
        .then(() => {
          this.$toast.success(this.$t("success"));
        })
        .catch((error) => {
          this.$toast.error(this.$t("fail"));
          this.handleError(error);
        })
        .finally(() => {
          item.isLoading = false;
        });
    },
  },
};
</script>
